import React from 'react';
import AllowlistMigrationMediator from './allowlist-migration/AllowlistMigrationMediator';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const AllowlistMigrationForm = ({ sfAccountCanonicalId }) => {
  const [showModal, setShowModal] = React.useState(false);
  const onClose = () => setShowModal(false);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  return (
    <div className="mb-4">
      <h5>Allowlist migration</h5>
      <p>
        This migration will allow you to move your existing allowlist to be now managed by HRIS.
      </p>
      <div className="col-sm-3 px-1 ps-0">
        <button
          className="btn btn-secondary w-100"
          onClick={() => setShowModal(true)}
          disabled={!isEditable}
        >
          Migrate Allowlist
        </button>
      </div>
      <AllowlistMigrationMediator
        isOpen={showModal}
        onClose={onClose}
        sfAccountCanonicalId={sfAccountCanonicalId}
      />
    </div>
  );
};

export default AllowlistMigrationForm;
