// @flow
import React, { useMemo, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import SettingManagerSubRoutes from './SettingManagerSubRoutes';
import CompanyFeaturesContext, {
  COMPANY_FEATURES,
  CompanyFeaturesContextProvider,
} from '../context/CompanyFeaturesContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  QUALITRAIN_OPS_VIEWER,
  userHasAnyRole,
} from '../../../common/authentication/roles';
import { NetworkSettingsContextProvider } from '../../offer-manager/context/NetworkSettingsContext';

type SubRouteLinkProps = {
  path: string,
  text: string,
};

const SubRouteLink = ({ path, text }: SubRouteLinkProps) => (
  <NavLink className={({ isActive }) => `nav-item nav-link ${isActive ? ' active' : ''}`} to={path}>
    {text}
  </NavLink>
);

const SettingManagerTemplateWithSubRoutes = () => {
  const sfAccountId = useParams().sfAccountCanonicalId;
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [
    QUALITRAIN_OPS_ADMIN,
    QUALITRAIN_OPS_MASTER,
    QUALITRAIN_OPS_VIEWER,
  ]);
  const { isFeatureEnabled } = useContext(CompanyFeaturesContext);
  const isAllowlistEnabled = useMemo(
    () => isFeatureEnabled(COMPANY_FEATURES.ALLOWLIST.name),
    [isFeatureEnabled]
  );
  const showAllowlist = isEditable && isAllowlistEnabled;

  return (
    <>
      <nav className="nav nav-tabs flex-column flex-sm-row justify-content-center">
        <SubRouteLink path={`/companies/setting-manager/${sfAccountId}/setting`} text="General" />
        {isEditable && (
          <SubRouteLink
            path={`/companies/setting-manager/${sfAccountId}/eligibility-rules`}
            text="HRIS & Eligibility Rules"
          />
        )}
        {showAllowlist && (
          <SubRouteLink
            path={`/companies/setting-manager/${sfAccountId}/edit-allowlist`}
            text="Edit Allowlist"
          />
        )}
        <SubRouteLink
          path={`/companies/setting-manager/${sfAccountId}/logs`}
          text="Allowlist Activity"
        />
      </nav>
      <div className="mt-4">
        <SettingManagerSubRoutes />
      </div>
    </>
  );
};

const SettingManagerTemplateWithContext = props => (
  <CompanyFeaturesContextProvider>
    <NetworkSettingsContextProvider>
      <SettingManagerTemplateWithSubRoutes {...props} />
    </NetworkSettingsContextProvider>
  </CompanyFeaturesContextProvider>
);

export default withPageTemplate(SettingManagerTemplateWithContext, {
  headerTitle: 'Company Settings',
});
